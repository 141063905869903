<template>
<div class="container menu" id="menu">
    <div class="row menu-category" v-for="category in menu" :key="category">
        <div class="col-md-6">
        <h3>{{category.name}}</h3>
        <p v-if="category.toppings" class="toppings">{{category.toppings}}</p>
       <ul class="menu-list">
           <li v-for="item in category.items" :key="item"><span class="menu-item">{{ item }}</span><span class="dotted-seperator"></span>
           </li>
       </ul>
        </div>
        <div class="col-md-6"><img :src="require('../assets/' + category.image)" class="img-fluid"></div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
        menu: [{
                name: "Burgers",
                image: "burger.jpg",
                toppings: "Available Toppings: Lettuce, tomato, onion, pickles, grilled mushrooms, grilled, onions, jalapenos, ketchup, mustard, relish, mayonnaise, hot sauce, and BBQ sauce.",
                items: [
                    "Burger",
                    "Cheeseburger",
                    "Bacon Burger",
                    "Bacon Cheeseburger",
                    "Turkey Burger",
                    "Kids Burger",
                    "Kids Cheeseburger",
                    "Kids Turkey Burger",
                ],
            },
            {
                name: "Dogs",
                image: "hotdog.jpg",
                toppings: "Available Toppings: Lettuce, tomato, onion, pickles, grilled mushrooms, grilled, onions, jalapenos, ketchup, mustard, relish, mayonnaise, hot sauce, and BBQ sauce, Sauerkraut.",
                items: [
                    "Nathans Hot Dog",
                    "Bacon Dog",
                    "Cheese Dog",
                    "Bacon Cheese Dog",
                ],
        },
            {
                name: "New Additions / Sides / Beverages",
                image: "nuggets.jpg",
                items: [
                    "Chicken Sandwich",
"10pc Chicken Nuggets",
"Slider Box",
"Salad Kit",
"Sweet Potato Fries",
"Milkshake"
                ],
        }
        ]
    }
}
}
</script>
<style scoped>
.menu-category {
    margin: 4em 0;
}
.menu-category h3 {
    font-weight: 600;
    margin-top: .5em;
}
ul {
list-style: none;
font-weight: 600;
}
li {
    margin-left: -40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
line-height: 2em;
}
.menu-item {
margin-right: 1em;
white-space: nowrap
}
.dotted-seperator {
    border-bottom: 2px dotted #61ce70;
    width: 100%;
}
</style>