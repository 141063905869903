<template>
  <div class="nav-wrapper">
    <div class="container">
      <b-navbar toggleable="md" type="light">
        <b-navbar-brand>
          <a href="/">
            <img
              src="@/assets/logo.png"
              class="img-fluid;"
              alt="Fatty Patty Restaurants Logo"
            />
          </a>
        </b-navbar-brand>
        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="x"></b-icon>
            <b-icon v-else icon="list"></b-icon>
          </template>
        </b-navbar-toggle>
        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item
              v-for="nav in nav"
              :key="nav.name"
              :to="nav.path"
              :target="nav.path.startsWith('https://') ? '_blank' : '_self'"
              >{{ nav.name }}</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteHeader",
  data() {
    return {
      nav: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Menu",
          path: "#menu",
        },
        {
          name: "Contact",
          path: "#locations",
        },
        {
          name: "Order Online",
          path: "https://www.toasttab.com/locations/bbe4ee6e-ba12-4987-a358-9e0148a86409",
        },
        {
          name: "Delivery",
          path: "#locations",
        },
        {
          name: "Gift Cards",
          path: "https://www.toasttab.com/fattypatty-trumbull/giftcards",
        },
      ],
    };
  },
};
</script>

<style scoped>
.nav-wrapper {
  background: #61ce70;
  color: #fff;
}
.navbar {
  gap: 15px;
}
.navbar a {
  color: #fff !important;
  font-weight: 600;
}
.navbar-nav {
  align-items: flex-start;
  gap: 15px;
}
.navbar-collapse {
  justify-content: flex-end;
}
.navbar-brand {
  padding-right: 1.5em;
}
.navbar-brand img {
  max-width: 100px;
}
.navbar-light .navbar-toggler {
  color: #fff;
  border: none;
  padding: 0;
  font-size: 2rem;
}
</style>
