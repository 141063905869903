<template>
<div class="footer">
  <div class="container">
      <div class="footer-logo">
          <a href="/">
  <img src="@/assets/logo.png" class="img-fluid;" alt="Fatty Patty Restaurants Logo">
  </a>
      </div>
      <div class="footer-social">
        <a href="https://www.instagram.com/fattypattyrestaurants/"><b-icon-instagram/></a>
        <a href="https://www.facebook.com/FATTY-PATTY-2097302783837828/"><b-icon-facebook/></a>
      </div>
      <div class="footer-copyright">
        ®{{new Date().getFullYear()}} Fatty Patty Restaurants

      </div>
</div>
</div>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
  }
}
}
</script>

<style scoped>
.footer {
  background: #61CE70;
  color: #fff;
  display: flex;
  padding: 2em;
      flex-direction: column;
    align-items: center;
    align-content: center;
text-align: center;
}
.footer img {
  max-width: 100px;
  margin: 0 auto;
}
.footer-social {
  margin-top: 1em;
}
.footer-social a {
  padding: 5px;
}
.footer-social svg{
color: #fff;
font-size: 24px;
}
.footer-copyright {
  font-size: .8em;
  margin-top: 2em;
  margin-bottom: -1em;
}
</style>