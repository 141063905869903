<template>
<div class="hero-banner">
    <div class="hero-content">
        <h1>{{ headline }}</h1>
        <a href="https://www.toasttab.com/locations/bbe4ee6e-ba12-4987-a358-9e0148a86409" class="btn btn-primary rounded-pill" target="_blank">Order Online</a>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
        headline: "Burgers. Dogs. Fries. Shakes."
    }
}
}
</script>
<style scoped>
.hero-banner {
    background-image: linear-gradient( rgba(0,0,0,0.25), rgba(0, 0, 0, 0.25)),url('~@/assets/hero.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.hero-content h1{
    font-weight: 800;
    font-size: 6em;
    max-width: 800px;
    margin: 0 auto;
    color: white;
    margin-bottom: 20px;
    line-height: 1em;
    text-transform: uppercase;
}
@media(max-width: 767px) {
    .hero-content h1{
    font-size: 3em;
    }
    .hero-banner {
        height: 300px;
    }
}
</style>