<template>
    <div class="container locations" id="locations">
        <div class="row location-row">
            <div class="location" v-for="location in locations" :key="location">
                <!-- <div class="location-name">
        <h3>{{location.location}}</h3>
            </div> -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="location-info">
                            <div class="icon-list">
                                <div class="location-phone-icon"><b-icon-phone-fill /></div>
                                <div class="location-phone">
                                    <h4>Call:</h4>
                                    <p><a :href="'tel:+1' + location.phone">{{ location.phone }}</a></p>
                                </div>
                            </div>
                            <div class="icon-list">
                                <div class="location-address-icon"><b-icon-geo-alt-fill /></div>
                                <div class="location-address">
                                    <h4>Address:</h4>
                                    <p>{{ location.address }}<br>{{ location.city }}</p>
                                </div>
                            </div>
                            <div class="icon-list">
                                <div class="location-hours-icon"><b-icon-clock-fill /></div>
                                <div class="location-hours">
                                    <h4>Hours:</h4>
                                    <table>
                                        <tr>
                                            <td>{{ location.hours.days }}</td>
                                            <td>{{ location.hours.time }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="icon-list">
                                <div class="location-delivery-icon"><b-icon-bag-fill /></div>
                                <div class="location-delivery">
                                    <h4>Order Delivery:</h4>
                                    <div class="delivery-methods">
                                        <a v-for="item in location.delivery" class="btn btn-primary rounded-pill"
                                            :key="item.name" :href="item.link" target="_blank">{{ item.name }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="location-map">
                            <iframe :src="location.map" frameborder="0" height="600px"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            locations: [
                {
                    location: "Shelton",
                    phone: "475-269-5105",
                    address: "762 River Rd.",
                    city: "Shelton, CT 06484",
                    map: "https://maps.google.com/maps?q=fatty%20patty%20shelton&t=m&z=13&output=embed&iwloc=near",
                    hours: {
                        days: "Sunday-Saturday",
                        time: "11:00am - 9:00pm"
                    },
                    delivery: [{
                        name: "GrubHub",
                        link: "https://www.grubhub.com/restaurant/fatty-patty-762-river-rd-shelton/2435458",
                    },
                    {
                        name: "Uber Eats",
                        link: "https://www.ubereats.com/connecticut/food-delivery/fatty-patty-shelton/dpIflaDrSTmRZPJisnuWuA",
                    }]
                }
            ]
        }
    }
}
</script>
<style scoped>
.location-row {
    margin: 4em 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 2em;
}

.location {
    flex-grow: 1;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0 0 20px 0 rgb(0 0 0 / 8%); */
}

.location-name {
    background-color: #61ce70;
    text-align: center;
    padding: 1.3em;
    color: #fff;
}

.location-name h3 {
    font-weight: 600;
    margin: 0;
}

.location-info {
    padding: 2em;
}

.location-info a {
    color: #2c3e50;
}

.location-info a:hover {
    color: #61ce70;
    text-decoration: none;
}

.location-info h4 {
    font-weight: 600;
    line-height: 1em;
}

.location-map iframe {
    width: 100%;
    height: 500px;
    margin-top: 1em;
}

.icon-list {
    display: flex;
    margin-bottom: .8em;
}

.icon-list svg {
    font-size: 40px;
    border-radius: 50%;
    background-color: #61ce70;
    padding: .6rem;
    color: #fff;
    margin-right: 15px;
}

.location-hours table {
    margin-bottom: 1em;
}

.location-hours td {
    width: 125px;
}

.delivery-methods {
    display: flex;
    flex-wrap: wrap;
}

.delivery-methods .btn-primary {
    flex-grow: 1;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: .8em 2em !important;
    color: #fff;
}

@media(max-width: 767px) {
    .location-info {
        padding: 2em 1.5em;
    }

    .location-hours td {
        display: table-row;
    }
}
</style>