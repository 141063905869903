// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3EHcCbswWHe8YDDV2tgpk32AbJxbi79w",
  authDomain: "fattypattyrestaurants.firebaseapp.com",
  projectId: "fattypattyrestaurants",
  storageBucket: "fattypattyrestaurants.appspot.com",
  messagingSenderId: "1055054541229",
  appId: "1:1055054541229:web:91d2c4c7f06d763ccbf124",
  measurementId: "G-J6YXT97XEV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (window.location.hostname == "fattypattyrestaurants.com") {
    getAnalytics(app)
  }