<template>
  <div id="app">
    <Header/>
    <HeroBanner/>
    <Menu/>
    <Locations/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import HeroBanner from './components/HeroBanner.vue'
import Menu from './components/Menu.vue'
import Locations from './components/Locations.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    HeroBanner,
    Menu,
    Locations,
    Footer
  }
}
</script>

<style>

#app {
  font-family: "Roboto Condensed", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.btn-primary {
  background: #61CE70!important;
  border-color: #61CE70!important;
  color: #fff;
  }
  .btn-primary:hover {
        background-color: #333!important;
    border-color: #333!important;
    color: #fff!important;
  }
  .btn {
    padding: 1em 3em!important;
    text-transform: uppercase!important;
    font-size: 13px!important;
    font-weight: 600!important;
    letter-spacing: 1px;
  }
.container {
  max-width: 960px!important;
}
html {
  scroll-behavior: smooth;
}
</style>
